import { Chess } from "chess.js"; // Import the Chess object
import { database } from "./firebaseConfig";
import { ref, set, update, remove, onValue } from "firebase/database";

export const createLobby = async (
  lobbyCode,
  nickname,
  setLobbyCode,
  setPgn,
  setGame,
  setHistory,
  setMovesLeft,
  setIsCreator,
  getLatestGamesPgn,
  startGame
) => {
  const newPgn = await getLatestGamesPgn();
  if (newPgn) {
    const code =
      lobbyCode || Math.random().toString(36).substring(2, 6).toUpperCase();
    setLobbyCode(code);
    setPgn(newPgn);
    const newGame = new Chess();
    newGame.loadPgn(newPgn);
    const gameHistory = newGame.history({ verbose: true });

    setGame(new Chess()); // Reset to initial position
    setHistory(gameHistory);
    setMovesLeft(gameHistory.length); // Set the total number of moves
    setIsCreator(true);

    // Initialize the Firebase path for the lobby
    const lobbyRef = ref(database, `lobbies/${code}`);
    set(lobbyRef, {
      gameLink: "",
      guesses: {},
      players: {
        [nickname]: { nickname, ready: false },
      },
      gameStatus: "waiting",
      pgn: newPgn,
      leaderboard: {},
      nextGame: null, // Initialize next game trigger
    });

    startGame(); // Automatically start the game
  }
};

export const joinLobby = (lobbyCode, nickname, setLobbyCode) => {
  const code = lobbyCode.toUpperCase();
  setLobbyCode(code);

  const playerRef = ref(database, `lobbies/${code}/players/${nickname}`);
  set(playerRef, { nickname, ready: false });
};

export const cleanupLobby = (lobbyCode, nickname) => {
  if (lobbyCode) {
    const playerRef = ref(database, `lobbies/${lobbyCode}/players/${nickname}`);
    remove(playerRef).then(() => {
      // If no players are left, delete the lobby
      const playersRef = ref(database, `lobbies/${lobbyCode}/players`);
      onValue(playersRef, (snapshot) => {
        if (!snapshot.exists()) {
          const lobbyRef = ref(database, `lobbies/${lobbyCode}`);
          remove(lobbyRef).catch((error) =>
            console.error("Error deleting lobby:", error)
          );
        }
      });
    });
  }
};
