import React, { useState, useEffect } from "react";
import axios from "axios";
import { Chess } from "chess.js";
import { Chessboard } from "react-chessboard";
import { database } from "./firebaseConfig";
import {
  Box,
  Button,
  TextField,
  Typography,
  Modal,
  List,
  ListItem,
  Divider,
} from "@mui/material";
import { ref, set, onValue, remove, get } from "firebase/database";
import "./App.css";
import { createLobby, joinLobby, cleanupLobby } from "./Lobby";

const emojis = ["😀", "🎉", "👍", "❤️", "😂", "😮"];

function GameComponent() {
  const [nickname, setNickname] = useState("");
  const [lobbyCode, setLobbyCode] = useState("");
  const [tempLobbyCode, setTempLobbyCode] = useState("");
  const [game, setGame] = useState(new Chess());
  const [pgn, setPgn] = useState("");
  const [playing, setPlaying] = useState(false);
  const [moveIndex, setMoveIndex] = useState(0);
  const [history, setHistory] = useState([]);
  const [isCreator, setIsCreator] = useState(false);
  const [whiteElo, setWhiteElo] = useState(null);
  const [blackElo, setBlackElo] = useState(null);
  const [movesLeft, setMovesLeft] = useState(null);
  const [ratingGuess, setRatingGuess] = useState("");
  const [guesses, setGuesses] = useState({});
  const [players, setPlayers] = useState({});
  const [leaderboard, setLeaderboard] = useState({});
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [activeEmojis, setActiveEmojis] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [showBottomBar, setShowBottomBar] = useState(true);
  const [scoreIncrements, setScoreIncrements] = useState([]);
  const [finalScore, setFinalScore] = useState(0);
  const [revealScore, setRevealScore] = useState(false);
  const [showGameLinkButton, setShowGameLinkButton] = useState(false);
  const [gameLink, setGameLink] = useState("");

  useEffect(() => {
    if (lobbyCode.length < 4) return;

    const playersRef = ref(database, `lobbies/${lobbyCode}/players`);
    const guessesRef = ref(database, `lobbies/${lobbyCode}/guesses`);
    const gameStatusRef = ref(database, `lobbies/${lobbyCode}/gameStatus`);
    const leaderboardRef = ref(database, `lobbies/${lobbyCode}/leaderboard`);
    const nextGameRef = ref(database, `lobbies/${lobbyCode}/nextGame`);
    const emojiRef = ref(database, `lobbies/${lobbyCode}/emoji`);

    onValue(playersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setPlayers(data);
      }
    });

    onValue(guessesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setGuesses(data);
      }
    });

    onValue(gameStatusRef, (snapshot) => {
      const data = snapshot.val();
      if (data === "started") {
        initializeGameOnAllClients();
      }
    });

    onValue(leaderboardRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setLeaderboard(data);
      }
    });

    onValue(emojiRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        showFloatingEmoji(data.emoji, data.sender);
      }
    });

    onValue(nextGameRef, (snapshot) => {
      const data = snapshot.val();
      if (data === "triggered") {
        setCountdown(5);
        let count = 5;

        const countdownInterval = setInterval(() => {
          count -= 1;
          setCountdown(count);

          if (count === 0) {
            clearInterval(countdownInterval);
            setCountdown(null);
            initializeNextGame();
          }
        }, 1000);
      }
    });
  }, [lobbyCode]);

  const handleLobbyCodeChange = (e) => {
    const code = e.target.value.toUpperCase();
    if (code.length <= 4) {
      setTempLobbyCode(code);
      if (code.length === 4) {
        setLobbyCode(code);
      }
    }
  };

  const sendEmoji = (emoji) => {
    if (playing && lobbyCode && nickname) {
      const emojiRef = ref(database, `lobbies/${lobbyCode}/emoji`);
      set(emojiRef, {
        emoji,
        sender: nickname,
      }).catch((error) => {
        console.error("Error sending emoji:", error);
      });
    }
  };

  const showFloatingEmoji = (emoji, sender) => {
    const id = Math.random().toString(36).substring(2, 9); // Unique ID for each emoji
    setActiveEmojis((prevEmojis) => [...prevEmojis, { id, emoji, sender }]);

    setTimeout(() => {
      setActiveEmojis((prevEmojis) => prevEmojis.filter((e) => e.id !== id));
    }, 3000); // Remove after 3 seconds
  };

  const resetGameState = () => {
    setGame(new Chess());
    setMoveIndex(0);
    setHistory([]);
    setPlaying(false);
    setWhiteElo(null);
    setBlackElo(null);
    setRatingGuess("");
    setGuesses({});
    setShowLeaderboard(false);
    setMovesLeft(null);
    setShowBottomBar(true);
    setRevealScore(false);
    setShowGameLinkButton(false);
  };

  const initializeNextGame = async () => {
    resetGameState();

    if (isCreator) {
      let newPgn = null;
      while (!newPgn || !hasMoreThan20Moves(newPgn)) {
        newPgn = await getLatestGamesPgn();
      }
      setPgn(newPgn);

      const newGame = new Chess();
      newGame.loadPgn(newPgn);

      setWhiteElo(newGame.header().WhiteElo || "Unknown");
      setBlackElo(newGame.header().BlackElo || "Unknown");
      setGameLink(newGame.header().Link || ""); // Ensure all players get the game link

      await createLobby(
        lobbyCode,
        nickname,
        setLobbyCode,
        setPgn,
        setGame,
        setHistory,
        setMovesLeft,
        setIsCreator,
        getLatestGamesPgn,
        startGame
      );
      startGame();
    } else {
      const gameStatusRef = ref(database, `lobbies/${lobbyCode}/gameStatus`);
      set(gameStatusRef, "started");
    }
  };

  const hasMoreThan20Moves = (pgn) => {
    const newGame = new Chess();
    newGame.loadPgn(pgn);
    return newGame.history().length > 20;
  };

  const calculateScores = (guesses) => {
    let newLeaderboard = { ...leaderboard };
    const whiteEloInt = parseInt(whiteElo, 10);
    const blackEloInt = parseInt(blackElo, 10);

    if (!isNaN(whiteEloInt) && !isNaN(blackEloInt)) {
      const actualElo = (whiteEloInt + blackEloInt) / 2;

      Object.keys(guesses).forEach((player) => {
        const guess = parseInt(guesses[player].guess, 10);
        const guessDifference = Math.abs(guess - actualElo);
        let correctnessScore = 10 - Math.min(10, guessDifference / 100); // Scale to 1-10 points
        correctnessScore = Math.max(1, Math.round(correctnessScore));

        const time = guesses[player].time
          ? new Date().getTime() - guesses[player].time
          : 0;
        const moveBasedBonus = 5 - Math.min(5, moveIndex / 4); // 1-5 points based on speed
        const speedBonus = Math.max(1, Math.round(moveBasedBonus));

        const totalScore = correctnessScore + speedBonus;

        newLeaderboard[player] = (newLeaderboard[player] || 0) + totalScore;

        // Add scoring details for animation
        if (player === nickname) {
          setScoreIncrements([
            { player, correctnessScore, speedBonus, totalScore },
          ]);
        }
      });

      setLeaderboard(newLeaderboard);

      const leaderboardRef = ref(database, `lobbies/${lobbyCode}/leaderboard`);
      set(leaderboardRef, newLeaderboard).catch((error) => {
        console.error("Error updating leaderboard in Firebase:", error);
      });

      // Reveal the score after 1 second
      setTimeout(() => {
        setRevealScore(true);
        setFinalScore(newLeaderboard[nickname] || 0);

        // Show the game link button 1 second after the score is revealed
        setTimeout(() => {
          setShowGameLinkButton(true);
        }, 1000);
      }, 1000);
    }
  };

  const getRandomActivePlayer = async () => {
    const countryCodes = ["US", "RU", "IN", "BR", "CN"];
    const countryCode =
      countryCodes[Math.floor(Math.random() * countryCodes.length)];
    const url = `https://api.chess.com/pub/country/${countryCode}/players`;

    try {
      const response = await axios.get(url);
      const players = response.data.players;
      if (players.length > 0) {
        return players[Math.floor(Math.random() * players.length)];
      }
    } catch (error) {
      console.error("Error fetching players:", error);
    }

    return null;
  };

  const cleanPgn = (pgn) => {
    return pgn.replace(/\{[^}]*\}/g, "").trim();
  };

  const getLatestGamesPgn = async () => {
    const player = await getRandomActivePlayer();
    if (player) {
      const url = `https://api.chess.com/pub/player/${player}/games/archives`;

      try {
        const response = await axios.get(url);
        const archives = response.data.archives;
        if (archives.length > 0) {
          const latestArchiveUrl = archives[archives.length - 1];
          const gamesResponse = await axios.get(latestArchiveUrl);
          const games = gamesResponse.data.games;
          if (games.length > 0) {
            const randomGame = games[Math.floor(Math.random() * games.length)];
            setGameLink(randomGame.url);
            return cleanPgn(randomGame.pgn);
          }
        }
      } catch (error) {
        console.error("Error fetching games:", error);
      }
    }
    return null;
  };

  const initializeGameOnAllClients = async () => {
    const pgnRef = ref(database, `lobbies/${lobbyCode}/pgn`);
    const pgnSnapshot = await get(pgnRef);
    const pgnData = pgnSnapshot.val();

    const newGame = new Chess();
    newGame.loadPgn(pgnData);
    const gameHistory = newGame.history({ verbose: true });

    setGame(new Chess());
    setHistory(gameHistory);
    setMoveIndex(0);
    setPlaying(true);
    setMovesLeft(gameHistory.length);

    setWhiteElo(newGame.header().WhiteElo || "Unknown");
    setBlackElo(newGame.header().BlackElo || "Unknown");
    setGameLink(newGame.header().Link || ""); // Ensure all players get the game link
  };

  const startGame = () => {
    const gameStatusRef = ref(database, `lobbies/${lobbyCode}/gameStatus`);
    set(gameStatusRef, "started");
  };

  const submitGuess = () => {
    if (ratingGuess && nickname && lobbyCode) {
      const guessRef = ref(
        database,
        `lobbies/${lobbyCode}/guesses/${nickname}`
      );
      set(guessRef, {
        nickname,
        guess: ratingGuess,
        time: new Date().getTime(),
      }).catch((error) => {
        console.error("Error submitting guess:", error);
      });
      setShowBottomBar(false); // Hide bottom bar after submitting a guess
    }
  };

  const startNextGame = () => {
    if (isCreator) {
      const nextGameRef = ref(database, `lobbies/${lobbyCode}/nextGame`);
      set(nextGameRef, "triggered");
    }
  };

  useEffect(() => {
    if (playing && moveIndex < history.length) {
      const timer = setTimeout(() => {
        game.move(history[moveIndex]);
        setGame(new Chess(game.fen()));
        setMoveIndex(moveIndex + 1);
        setMovesLeft(history.length - moveIndex - 1);

        if (moveIndex >= history.length - 1) {
          setPlaying(false);
          calculateScores(guesses);
          setShowLeaderboard(true);
          setModalOpen(true); // Open modal when the game ends
        }
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [playing, moveIndex, history, game]);

  useEffect(() => {
    window.addEventListener("beforeunload", () =>
      cleanupLobby(lobbyCode, nickname)
    );
    return () => {
      window.removeEventListener("beforeunload", () =>
        cleanupLobby(lobbyCode, nickname)
      );
      cleanupLobby(lobbyCode, nickname);
    };
  }, [lobbyCode]);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#424242",
    border: "2px solid #000",
    boxShadow: 24,
    padding: 4,
    color: "white",
    zIndex: 2000,
  };

  return (
    <Box className="game-wrapper">
      <Box className="left-sidebar" p={4}>
        {!lobbyCode ? (
          <>
            <Typography variant="h4">Enter Game Details</Typography>
            <TextField
              label="Enter your nickname"
              variant="outlined"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
              fullWidth
              margin="normal"
              InputProps={{ style: { color: "white" } }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                createLobby(
                  lobbyCode,
                  nickname,
                  setLobbyCode,
                  setPgn,
                  setGame,
                  setHistory,
                  setMovesLeft,
                  setIsCreator,
                  getLatestGamesPgn,
                  startGame
                )
              }
              fullWidth
              sx={{ mt: 2 }}
            >
              Create Lobby
            </Button>
            <TextField
              label="Enter lobby code"
              variant="outlined"
              value={tempLobbyCode}
              onChange={handleLobbyCodeChange}
              fullWidth
              margin="normal"
              InputProps={{ style: { color: "white" } }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => joinLobby(lobbyCode, nickname, setLobbyCode)}
              fullWidth
              sx={{ mt: 2 }}
            >
              Join Lobby
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h4">Chess Elo Guessing Game</Typography>
            <Typography variant="h6">Lobby Code: {lobbyCode}</Typography>
            <Typography>
              Players in Lobby: {Object.keys(players).length}
            </Typography>
            <Typography>
              Share this code with friends. All players must enter this code to
              load the same game.
            </Typography>
          </>
        )}
      </Box>

      <Box className="chessboard-container">
        <Chessboard position={game.fen()} />
      </Box>

      <Box className="sidebar-container">
        {/* Right sidebar content */}
        <Typography variant="h6">Scoreboard</Typography>
        {movesLeft !== null && <Typography>Moves Left: {movesLeft}</Typography>}
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6">Leaderboard</Typography>
        {Object.keys(leaderboard).length > 0 ? (
          <List>
            {Object.keys(leaderboard).map((key) => (
              <ListItem key={key}>
                {key}: {Math.round(leaderboard[key])} points
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>No scores yet.</Typography>
        )}
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6">Guesses</Typography>
        {Object.keys(guesses).length > 0 ? (
          <List>
            {Object.keys(guesses).map((key) => (
              <ListItem key={key}>
                {guesses[key].nickname} guessed {guesses[key].guess}
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>No guesses yet.</Typography>
        )}
        {isCreator && (
          <Button
            variant="contained"
            color="error"
            onClick={startNextGame}
            fullWidth
            sx={{ mt: 2 }}
          >
            Next Game
          </Button>
        )}
      </Box>

      {showBottomBar && playing && (
        <Box
          className="bottom-bar"
          p={2}
          sx={{
            maxWidth: 600,
            mx: "auto",
            borderRadius: 2,
            backgroundColor: "#333",
            boxShadow: 3,
          }}
        >
          <TextField
            label="Enter your rating guess"
            variant="outlined"
            value={ratingGuess}
            onChange={(e) => setRatingGuess(e.target.value)}
            type="number"
            fullWidth
            margin="normal"
            InputProps={{ style: { color: "white" } }}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={submitGuess}
            fullWidth
          >
            Submit Guess
          </Button>
        </Box>
      )}

      <Box className="emoji-container">
        {emojis.map((emoji) => (
          <Button
            key={emoji}
            variant="contained"
            color="warning"
            onClick={() => sendEmoji(emoji)}
            sx={{ m: 1 }}
          >
            {emoji}
          </Button>
        ))}
      </Box>

      {activeEmojis.map((emojiData, index) => (
        <Box key={emojiData.id} className="floating-emoji">
          <Typography variant="h2">{emojiData.emoji}</Typography>
          <Typography variant="h6" mt={2}>
            Sent by {emojiData.sender}
          </Typography>
        </Box>
      ))}

      {countdown !== null && (
        <Box className="countdown">
          <Typography variant="h1" color="error">
            {countdown}
          </Typography>
        </Box>
      )}

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6">
            Game Results
          </Typography>
          <Typography variant="body1" id="modal-description" sx={{ mt: 2 }}>
            White Elo: {whiteElo}
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            Black Elo: {blackElo}
          </Typography>

          {revealScore && (
            <Box className="score-animation" sx={{ mt: 4 }}>
              {scoreIncrements.map((increment, index) => (
                <Typography
                  key={index}
                  variant="h4"
                  sx={{
                    fontFamily: "Impact",
                    color: "green",
                    animation: "fadeIn 1s",
                  }}
                >
                  +{increment.correctnessScore} points for correctness, +
                  {increment.speedBonus} points for speed (Total:{" "}
                  {increment.totalScore})
                </Typography>
              ))}
              <Typography variant="h3" sx={{ fontFamily: "Impact", mt: 4 }}>
                Total Score: {finalScore}
              </Typography>
            </Box>
          )}

          {showGameLinkButton && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.open(gameLink, "_blank")}
              fullWidth
              sx={{ mt: 2 }}
            >
              Check Game on Chess.com
            </Button>
          )}
        </Box>
      </Modal>
    </Box>
  );
}

export default GameComponent;
