import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// Your Firebase configuration object from Firebase console
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDOuN1VEpjsvfoutSiVNLgl8AgGLkFS4TA",
  authDomain: "eloguesser-98ca0.firebaseapp.com",
  databaseURL: "https://eloguesser-98ca0-default-rtdb.firebaseio.com",
  projectId: "eloguesser-98ca0",
  storageBucket: "eloguesser-98ca0.appspot.com",
  messagingSenderId: "11785155965",
  appId: "1:11785155965:web:41f7765d6f2516089a8814",
  measurementId: "G-KRH2ZZ8RDQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);

export { database };
